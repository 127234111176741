import React from "react"

import Layout from "../layouts"

export default function Thanks() {
  return (
    <Layout title="Thanks">
      <div className="container is-widescreen">
        <h1 className="title has-text-primary has-text-centered">
          Thank You! We'll get back to you the soonest possible
        </h1>
      </div>
    </Layout>
  )
}

